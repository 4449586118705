.menu-item {
    white-space: normal;
    word-break: break-word;
    line-height: 1.2;
    padding: 0; /* Elimina el padding adicional */
    text-align: left; /* Alinea el texto a la izquierda */
  }
  
  .menu-item a {
    display: block; /* Asegura que el enlace ocupe todo el espacio */
    padding: 10px 15px; /* Ajusta el padding según sea necesario */
  }